/* SPDX-FileCopyrightText: 2014-present Kriasoft <hello@kriasoft.com> */
/* SPDX-License-Identifier: MIT */

import history from "history/browser";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { App } from "./common";

// Render the top-level React component
ReactDOM.render(<App history={history} />, document.getElementById("root"));
