/* SPDX-FileCopyrightText: 2014-present Kriasoft <hello@kriasoft.com> */
/* SPDX-License-Identifier: MIT */

/**
 * Returns the currently logged in user object (`me`), `null` for anonymous
 * users, and `undefined` when the user status has not been resolved yet.
 */
export function useCurrentUser(): User | null | undefined {
  return null;
}

export type User = { id: string; email: string; name: string } | null;
